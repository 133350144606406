import CampaignServices from "@/services/CampaignServices";

// namespaced: true,

export const state = {
  campaigns: [],
  current_campaign: {},
  loading: false,
};

export const getters = {
  campaigns(state) {
    return state.campaigns;
  },
  currentCampaign(state) {
    return state.current_campaign;
  },
  loading(state) {
    return state.loading;
  },
};

export const actions = {
  async findAll({ commit }) {
    commit("SET_LOADING", true);
    const { data } = await CampaignServices.fetchAll();
    commit("SET_CAMPAIGNS", data.data.campaigns);
    commit("SET_LOADING", false);
  },
  async findOne({ commit }, campaignId) {
    commit("SET_LOADING", true);
    const { data } = await CampaignServices.fetchOne(campaignId);
    commit("SET_CURRENT_CAMPAIGN", data.data.campaign);
    commit("SET_LOADING", false);
  },
  async setCurrentCampaign({ commit }, campaign) {
    commit("SET_CURRENT_CAMPAIGN", campaign);
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
};

export const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns;
  },
  SET_CURRENT_CAMPAIGN(state, campaign) {
    state.current_campaign = campaign;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};
