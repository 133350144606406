export default [
  {
    path: "/fields",
    name: "fields.list",
    meta: { title: "Sites", authRequired: true },
    component: () => import("../views/fields/list"),
  },
  {
    path: "/fields/:id?/update",
    name: "fields.update",
    meta: { title: "Modifier site", authRequired: true },
    component: () => import("../views/fields/update"),
  },
  {
    path: "/fields/:id?/show",
    name: "fields.details",
    meta: { title: "Site détails", authRequired: true },
    component: () => import("../views/fields/details"),
  },
];
