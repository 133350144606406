export default [
  {
    path: "/subscriptions",
    name: "subscriptions.list",
    meta: { title: "Liste des souscriptions", authRequired: true },
    component: () => import("../views/subscriptions/list"),
  },
  {
    path: "/subscriptions/:id?/view",
    name: "subscriptions.details",
    meta: { title: "Détails des souscriptions", authRequired: true },
    component: () => import("../views/subscriptions/details"),
  },
];
