import Axios from "@/config/axios";

export default {
  async fetchSenders() {
    return Axios.get("/admin/system/params/senders");
  },
  async fetchPartner() {
    return Axios.get("/admin/system/params/partner");
  },

  async fetchDashboard() {
    return Axios.get("/admin/system/dashboard");
  },
};
